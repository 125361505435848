import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../../../components/seo";
import Skel from "../../../components/skel";
import { Widget } from '@typeform/embed-react'

const GoTabSubmitLead = () => (
  <Skel>
    <SEO title="Taplist.io &times; GoTab: Submit a Lead" noIndex />
    <section
      className="section bg-center bg-cover effect-section"
      style={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <div className="mask theme-bg opacity-8"></div>
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h2 className="white-color h1 m-20px-b">Submit a lead</h2>
          </Col>
        </Row>
      </Container>
    </section>
    <main>
      <Container className="p-3">
        <Row style={{ marginTop: "2em" }}>
          <Col md={{ span: 12 }}>
            <Widget id="YjGNlBE2" style={{ minHeight: '850px', width: '100%' }} />
          </Col>
        </Row>
      </Container>
    </main>
  </Skel>
);

export default GoTabSubmitLead;
